.container {
    width: 300px;
    height: 200px;
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #000;
    border-radius: 8px;
    padding: 15px;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
}
input {
    margin-bottom: 10px;
}
.title {}

.author {}

.content {
    height: 60px;
}

.btn {
    margin-top: 30px;
    text-align: center;
}
.save_btn {
    border: none;
    background-color: dodgerblue;
    width: 40px;
    height: 30px;
    margin-right: 10px;
    cursor: pointer;
}

.close_btn {
    border: none;
    width: 40px;
    height: 30px;
    background-color: crimson;
    cursor: pointer;
}