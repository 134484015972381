.container {
    border: 1px solid #000;
    border-radius: 20px;
    width: 400px;
    height: 700px;
    padding: 10px;
    box-sizing: border-box;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

.upper {
    height: 10%;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #000;
}

.title {
    /* margin-left: 20px; */
}

.add_btn {
    border: none;
    background-color: dodgerblue;
    color: white;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    cursor: pointer;
}

.list {
    margin-top: 15px;
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    overflow: scroll;
}